export default class Menu {
  constructor() {
    this.body = document.querySelector('.body');
    this.menuAnchor = document.querySelector('#menu-anchor');
    this.menu = document.querySelector('#menu');
    this.navigation = document.querySelector('#navigation');

    this.menuItem = document.querySelectorAll('.navigation__list__item');
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    this.menuAnchor.addEventListener('click', () => this.toggleMenu());

    this.menuItem.forEach((item) => {
      item.addEventListener('click', () => this.toggleMenu());
    });
  }

  toggleMenu() {
    this.menu.classList.toggle('menu--open');
    this.navigation.classList.toggle('navigation--open');
    this.body.classList.toggle('body--overflow');
  }
}
