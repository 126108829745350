import 'lightgallery.js/dist/css/lightgallery.css';
import '../styles/swiper.min.css';
import '../styles/index.scss';

import 'lightgallery.js';

import Swiper from 'swiper';
import Dhalsim from 'dhalsim-js';
import Menu from './components/menu';
import Form from './components/form';

document.addEventListener('DOMContentLoaded', () => {
  const dhalsim = new Dhalsim();
  const form = new Form();
  const menu = new Menu();

  dhalsim.init();
  form.init();
  menu.init();

  const heroSlider = new Swiper('#slider-hero', {
    init: false,
    loop: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
  });

  const testimonialsSlider = new Swiper('.slider-testimonials', {
    init: false,
    spaceBetween: 50,
    centeredSlides: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.slider-testimonials__pagination',
    },
  });

  const eventsSlider = new Swiper('#events-slider', {
    init: false,
    centeredSlides: true,
    slidesPerView: 1,
    initialSlide: 1,
    slidesPerColumn: 2,
    slidesOffsetBefore: 30,
    slidesOffsetAfter: 30,
    spaceBetween: 10,
    width: 250,
    breakpointsInverse: true,
    breakpoints: {
      375: {
        slidesOffsetBefore: 40,
        slidesOffsetAfter: 40,
      },
      414: {
        spaceBetween: 20,
        slidesOffsetBefore: 80,
        slidesOffsetAfter: 80,
      },
    },
  });

  setTimeout(() => {
    heroSlider.init();
    testimonialsSlider.init();

    if (dhalsim.isMobile()) {
      eventsSlider.init();
    }
  }, 100);

  lightGallery(document.getElementById('events-gallery'), {
    thumbnail: true,
  });
});
