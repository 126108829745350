import Jason from 'jason-react-form';
import SendGrid from '@sendgrid/mail';
import formData from '../data/form-data';

export default class Form {
  init() {
    this.setupForm();

    this.sendgrid = SendGrid;

    SendGrid.setApiKey('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');
  }

  setupForm() {
    this.jasonForm = new Jason({
      element: document.querySelector('#babbo-form'),
      data: formData,
      name: 'babbo-form',
      onReady: () => { },
      onZipcodeFetchSuccess: data => data,
      onZipcodeFetchError: data => data,
      onSubmit: data => this.submitForm(data),
      onSubmitSuccess: data => this.submitForm(data),
      onSubmitFieldError: data => data,
      onSubmitError: data => data,
      onStepChange: () => { },
    });

    this.jasonForm.init();
  }

  submitForm() {
    const name = document.getElementById('name').value;
    const phone = document.getElementById('phone').value;
    const emailFrom = document.getElementById('email').value;
    const whyAboutUs = document.getElementById('why_about_us').value;
    const subject = document.getElementById('subject').value;
    const message = document.getElementById('message').value;

    const msg = {
      to: 'dev.williamcosta@gmail.com',
      from: emailFrom,
      subject,
      html: `
        Nome: ${name}<br/>
        Telefone: ${phone}<br/>
        Como nos conheceu? ${whyAboutUs}<br/><br/>
        Mensagem: ${message}
      `,
    };

    console.log('TCL: buttonSubmit.onclick -> msg', msg);

    this.sendgrid.send(msg);
  }
}
